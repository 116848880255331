import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';

import {AppApiUrls} from '../../app.api.urls';
import {DelaysRecallData, PossibleDelaysData} from '../interface/possible-delays.interface';

@Injectable()

export class PossibleDelaysService {
  cityId = localStorage.getItem('city');
  constructor(private http: HttpClient) { }

  getPossibleDelays(): Observable<PossibleDelaysData> {
    return this.http.get<PossibleDelaysData>(AppApiUrls.possibleDelays(), {
      params: {
        city_id: this.cityId
      }
    });
  }

  getDelaysRecall(from: string, to: string): Observable<DelaysRecallData> {
    return this.http.get<DelaysRecallData>(AppApiUrls.delayReportRecall(), {
      params: {
        from,
        to,
        city_id: this.cityId
      }
    });
  }

  postDelaysRecall(orderId: number): Observable<PossibleDelaysData> {
    const body = {
      order_id: orderId,
      user_id: localStorage.getItem('id')
    };
    return this.http.post<PossibleDelaysData>(AppApiUrls.delayRecall(), body);
  }
}

import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {PackagepanelComponent} from './packagepanel/packagepanel.component';
import {NotfoundComponent} from './notfound/notfound.component';
import {HomeComponent} from './home/home.component';
import {StoragesettingsComponent} from './storagesettings/storagesettings.component';
import {TechcardEditPriceComponent} from './storagesettings/techcard-edit-price/techcard-edit-price.component';
import {HelpPageComponent} from './help-page/help-page.component';
import {LoginPageComponent} from './login-page/login-page.component';
import {AuthGuard} from './@shared/login-page/auth.guard';
import {AccountantCashAccountingFullComponent} from './cash-accounting/accountant-cash-accounting/accountant-cash-accounting-full/accountant-cash-accounting-full.component';
import {ProfileComponent} from './profile/profile.component';
import {StorageSettingsHomeComponent} from './storagesettings/storage-settings-home/storage-settings-home.component';
import {RouteListsComponent} from './route-lists/route-lists.component';
import {BenchmarkComponent} from './benchmark/benchmark.component';
import {ModulesResolver} from './@shared/navbar/resolvers/modules.resolver';
import {ChangePrinterComponent} from './change-printer/components/change-printer.component';
import {SiteIndexComponent} from './site-index/components/site-index.component';
import {CreateRouteListComponent} from './route-lists/create-route-list/create-route-list.component';
import {EditRouteListComponent } from './route-lists/create-route-list/edit-route-list/edit-route-list.component';
import {ModuleActivationModule} from "./module-activation/module-activation.module";

const routes: Routes = [
    {path: 'login', component: LoginPageComponent},
    {
        path: '',
        canActivate: [AuthGuard],
        resolve: {
            modules: ModulesResolver
        },
        children: [
            {path: '', component: HomeComponent },
            {path: 'help', component: HelpPageComponent},
            {path: 'benchmark', component: BenchmarkComponent},
            {path: 'site-index', component: SiteIndexComponent},
            {path: 'change-printer', component: ChangePrinterComponent},
            {
                path: 'district-routes',
                loadChildren: () => import('./route-list-district/route-list-district.module').then(m => m.RouteListDistrictModule)
            },
            {
                path: 'system',
                loadChildren: () => import('./system/system.module').then(m => m.SystemModule)
            },
            {path: 'packagepanel', component: PackagepanelComponent},
            {path: 'routelist', component: RouteListsComponent},
            {path: 'routelist/create', component: CreateRouteListComponent},
            {path: 'routelist/edit', component: EditRouteListComponent},
            {
                path: 'storage/settings', component: StoragesettingsComponent, children: [
                    {path: '', component: StorageSettingsHomeComponent},
                    {path: 'price', component: TechcardEditPriceComponent},
                    {path: 'visibility',
                    loadChildren: () => import('./storagesettings/techcard-change-visibility/change-visibility.module').then(m => m.ChangeVisibilityModule)
                    },
                ]

            },
            {
                path: 'cash/accounting',
                loadChildren: () => import('./cash-accounting/cash-accounting.module').then(m => m.CashAccountingModule)
            },
            {path: 'profile', component: ProfileComponent},
            {path: 'cash/accounting/accountant/full', component: AccountantCashAccountingFullComponent},
            {
                path: 'waybills',
                loadChildren: () => import('./waybills/waybills.module').then(m => m.WaybillsModule)
            },
            {
                path: 'users',
                loadChildren: () => import('./users/users.module').then(m => m.UsersModule)
            },
            {
                path: 'quick-sale',
                loadChildren: () => import('src/app/quick-sale/quick-sale.module').then(m => m.QuickSaleModule)
            },
            {
                path: 'reports',
                loadChildren: () => import('./reports/reports.module').then(m => m.ReportsModule)
            },
            {
                path: 'collection',
                loadChildren: () => import('./collection/collection.module').then(m => m.CollectionModule)
            },
            {
                path: 'terminals',
                loadChildren: () => import('./_terminals/terminals.module').then(m => m.TerminalsModule)
            },
            {
                path: 'tech-card',
                loadChildren: () => import('./_product-management/product-management.module').then(m => m.ProductManagementModule)
            },
            {
                path: 'print-check',
                loadChildren: () => import('./print-check/print-check.module').then(m => m.PrintCheckModule)
            },
            {
                path: 'kitchen-info',
                loadChildren: () => import('./kitchen-info/kitchen-info/kitchen-info.module').then(m => m.KitchenInfoModule)
            },
            {
                path: 'personnel-tests',
                loadChildren: () => import('./_personnel-tests/personnel-tests.module').then(m => m.PersonnelTestsModule)
            },
            {
                path: 'support-statistics',
                loadChildren: () => import('./_support-statistics/module/support-statistics.module').then(m => m.SupportStatisticsModule)
            },
            {
                path: 'statistics',
                loadChildren: () => import('./_statistics/module/statistics.module').then(m => m.StatisticsModule)
            },
            {
                path: 'checkbox-admin',
                loadChildren: () => import('./checkbox/checkbox.module').then(m => m.CheckboxModule)
            },
            {
                path: 'pay-roll',
                loadChildren: () => import('./pay-roll/pay-roll.module').then(m => m.PayRollModule)
            },
            {
                path: 'banners',
                loadChildren: () => import('./add-banners/add-banners.module').then(m => m.AddBannersModule)
            },
            {
                path: 'site-users',
                loadChildren: () => import('./site-users/site-users.module').then(m => m.SiteUsersModule)
            },
            {
                path: 'mobile-notifications',
                loadChildren: () => import('./mobile-notification/mobile-notification.module').then(m => m.MobileNotificationModule)
            },
            {
                path: 'ingredients',
                loadChildren: () => import('./ingredients/ingredients.module').then(m => m.IngredientsModule)
            },
            {
                path: 'semifinish',
                loadChildren: () => import('./semifinish/semifinish.module').then(m => m.SemifinishModule)
            },
            {
                path: 'add-marks',
                loadChildren: () => import('./add-marks/add-marks.module').then(m => m.AddMarksModule)
            },
            {
                path: 'system-logs',
                loadChildren: () => import('./system-logs/system-logs.module').then(m => m.SystemLogsModule)
            },
            {
                path: 'binotel',
                loadChildren: () => import('./binotel/binotel.module').then(m => m.BinotelModule)
            },
            {
                path: 'bonus-conditions',
                loadChildren: () => import('./bonus-conditions/bonus-conditions.module').then(m => m.BonusConditionsModule)
            },
            {
                path: 'categories',
                loadChildren: () => import('./categories/categories.module').then(m => m.CategoriesModule)
            },
            {
                path: 'thumbnail',
                loadChildren: () => import('./thumbnails/thumbnails.module').then(m => m.ThumbnailsModule)
            },
            {
                path: 'phone-operator',
                loadChildren: () => import('./phone-operator/phone-operator.module').then(m => m.PhoneOperatorModule)
            },
            {
                path: 'bonus-report',
                loadChildren: () => import('./bonus-report/bonus-report.module').then(m => m.BonusReportModule)
            },
            {
                path: 'routelist-edit-history',
                loadChildren: () => import('./routelist-edit-history/routelist-edit-history.module').then(m => m.RouteListEditHistoryModule)
            },
            {
                path: 'free-card-products',
                loadChildren: () => import('./free-card-product/free-card-product.module').then(m => m.FreeCardProductModule)
            },
            {
                path: 'contragents',
                loadChildren: () => import('./contragents/contragents.module').then(m => m.ContragentsModule)
            },
            {
                path: 'city-about',
                loadChildren: () => import('./city-about/city-about.module').then(m => m.CityAboutModule)
            },
            {
                path: 'cash-accounting',
                loadChildren: () => import('./cash-accounting-checkbox/cash-accounting-checkbox.module')
                    .then(m => m.CashAccountingCheckboxModule)
            },
            {
                path: 'delivery-time',
                loadChildren: () => import('./delivery-time/delivery-time.module')
                    .then(m => m.DeliveryTimeModule)
            },
            {
                path: 'sop',
                loadChildren: () => import('./sop/sop.module').then(m => m.SopModule)
            },
            {
                path: 'city-group',
                loadChildren: () => import('./city-group/city-group.module').then(m => m.CityGroupModule)
            },
            {
                path: 'notifications',
                loadChildren: () => import('./notification/notification.module').then(m => m.NotificationModule)
            },
            {
                path: 'promocode',
                loadChildren: () => import('./promocode/promocode.module').then(m => m.PromocodeModule)
            },
            {
                path: 'socials',
                loadChildren: () => import('./socials/socials.module').then(m => m.SocialsModule)
            },
            {
                path: 'recommended-products',
                loadChildren: () => import('./recommended-products/recommended-products.module').then(m => m.RecommendedProductsModule)
            },
            {
                path: 'zone-reserve',
                loadChildren: () => import('./zone-reserve/zone-reserve.module').then(m => m.ZoneReserveModule)
            },
            {
                path: 'site-decoration',
                loadChildren: () => import('./site-decoration/site-decoration.module').then(m => m.SiteDecorationModule)
            },
            {
                path: 'benchmarking',
                loadChildren: () => import('./benchmarking/benchmarking.module').then(m => m.BenchmarkingModule)
            },
            {
                path: 'site-status-history',
                loadChildren: () => import('./site-status-history/site-status-history.module').then(m => m.SiteStatusHistoryModule)
            },
            {
                path: 'message-bot-access',
                loadChildren: () => import('./message-bot-access/message-bot-access.module').then(m => m.MessageBotAccessModule)
            },
            {
                path: 'possible-delays',
                loadChildren: () => import('./possible-delays/possible-delays.module').then(m => m.PossibleDelaysModule)
            },
            {
                path: 'points-range',
                loadChildren: () => import('./points-range/points-range.module').then(m => m.PointsRangeModule)
            },
            {
                path: 'external-ids',
                // tslint:disable-next-line:max-line-length
                loadChildren: () => import('./communication-with-external-ids/communication-with-external-ids.module').then(m => m.CommunicationWithExternalIdsModule)
            },
            {
                path: 'additional-products',
                loadChildren: () => import('./additional-products/additional-products.module').then(m => m.AdditionalProductsModule)
            },
            {
                path: 'tv-media',
                loadChildren: () => import('./tv-media-schedule/tv-media-schedule.module').then(m => m.TvMediaScheduleModule)
            },
            {
                path: 'module-activation',
                loadChildren: () => import('./module-activation/module-activation.module').then(m => m.ModuleActivationModule)
            },
            {
                path: 'report-call-center',
                loadChildren: () => import('./report-call-center/report-call-center.module').then(m => m.ReportCallCenterModule)
            },
            {
                path: 'delivery-zones',
                loadChildren: () => import('./delivery-zones/delivery-zones.module').then(m => m.DeliveryZonesModule)
            },
            {path: '**', component: NotfoundComponent},
        ]
    },

];

@NgModule({
    imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
    exports: [RouterModule]
})
export class AppRoutingModule {
}

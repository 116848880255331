<div class="container-fluid">
    <div *ngIf="citiesCallCenter && group != '0'" class="row my-2">
        <div class="col-12">
            <span class="font-weight-bold mr-3">{{'QUICK_SALE.CHOOSE_CITY' | translate}}: </span>
        <ng-container *ngFor="let city of citiesCallCenter.cities">
                <button [ngClass]="{'btn-success': city.id == cityLocal, 'btn-danger': city.sushi_is_enabled == 0}" class="btn btn-outline-success mr-1" (click)="changeCityQS(city.id, city.places[0].id)">{{city?.description?.name}}</button>
        </ng-container>
        </div>
    </div>
    <form>
        <form class="form-inline" style="margin-top: 1rem; margin-bottom: 1rem">
            <div class="form-group">

                <div *ngIf="group == '0'" class="input-group input-group-sm">
                    <a class="btn btn-success"
                       (click)="openInNewTab('new', '')">{{'QUICK-SALE.NEW_ORDER' | translate}}</a>
                </div>
                <div *ngIf="group != '0'" class="input-group input-group-sm">
                    <a class="btn btn-success"
                       (click)="changeCity('new','', cities)">{{'QUICK-SALE.NEW_ORDER' | translate}}</a>
                </div>

            </div>
            <div class="form-group ml-2">
                <div class="input-group input-group-sm">
                    <input class="form-control" placeholder="yyyy-mm-dd"
                           name="dp" [(ngModel)]="service.date" (ngModelChange)="changePlace()" ngbDatepicker
                           #d="ngbDatepicker">
                    <div class="input-group-append">
                        <button class="btn btn-outline-success calendar" (click)="d.toggle()"
                                type="button">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                 class="bi bi-calendar2-date" viewBox="0 0 16 16">
                                <path fill-rule="evenodd"
                                      d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM2 2a1 1 0 0 0-1 1v11a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1H2z"/>
                                <path
                                        d="M2.5 4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5H3a.5.5 0 0 1-.5-.5V4zm3.945 8.688V7.354h-.633A12.6 12.6 0 0 0 4.5 8.16v.695c.375-.257.969-.62 1.258-.777h.012v4.61h.675zm1.188-1.305c.047.64.594 1.406 1.703 1.406 1.258 0 2-1.066 2-2.871 0-1.934-.781-2.668-1.953-2.668-.926 0-1.797.672-1.797 1.809 0 1.16.824 1.77 1.676 1.77.746 0 1.23-.376 1.383-.79h.027c-.004 1.316-.461 2.164-1.305 2.164-.664 0-1.008-.45-1.05-.82h-.684zm2.953-2.317c0 .696-.559 1.18-1.184 1.18-.601 0-1.144-.383-1.144-1.2 0-.823.582-1.21 1.168-1.21.633 0 1.16.398 1.16 1.23z"/>
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
            <div class="form-group ml-2">

                // Если кликнули на уже открытый пункт, просто переключаем его состояние
                <div class="input-group input-group-sm">
                    <input class="form-control" id="searchTerm" type="text" name="searchTerm"
                           [(ngModel)]="service.searchTerm" placeholder="{{'GLOBAL.SEARCH' | translate}}"/>
                    <div class="input-group-append">
                        <button class="btn btn-danger btn-sm" (click)="service.searchTerm = ''">X</button>
                    </div>
                </div>

            </div>
            <div class="form-group ml-2" *ngIf="prof == '1'">

                <div class="input-group input-group-sm">
                    <button class="btn btn-danger btn-sm" (click)="exportToExcel('quick-sale')"
                            matTooltip="Якщо ви хочете зберегти усю таблицю потрібно вибрати всі данні">EXCEL
                    </button>
                </div>
            </div>
            <div class="form-group ml-2">

                <div class="input-group input-group-sm">
                    <button type="button" class="btn btn-outline-secondary btn-sm"
                            (click)="modalService.open(ReportQuickSale, {scrollable: false})">
                        {{'QUICK_SALE.REPORT_QS' | translate}}
                    </button>
                </div>
            </div>
            <div class="form-group ml-2 d-none">
                <label class="btn btn-outline-secondary btn-sm" style="width: 100%" ngbButtonLabel>
                    {{'QUICK_SALE.CASHIER' | translate}} <input type="checkbox" ngbButton
                                [(ngModel)]="cashier" [ngModelOptions]="{standalone: true}"
                                (ngModelChange)="modeCashier($event, 'cashier')"
                >
                </label>
            </div>
            <div class="form-group ml-2" *ngIf="cashier">
                <label class="btn btn-outline-secondary btn-sm" style="width: 100%" ngbButtonLabel>
                    {{'QUICK_SALE.ALL_ORDERS' | translate}} <input type="checkbox" ngbButton
                                          [(ngModel)]="allOrders" [ngModelOptions]="{standalone: true}"
                                          (ngModelChange)="modeCashier($event, 'all')"
                >
                </label>
            </div>
            <app-client-cash-accounting *ngIf="cashier" mode="pickup"
                                        (shiftCollectionId)="shiftCollectionId = $event"></app-client-cash-accounting>
        </form>
        <div class="row">
            <div class="col-sm-9">
                <div style="height: 70vh; overflow-y: scroll; overflow-x: hidden">
                    <table class="table table-striped table-sm" style="max-height: 100%;" width="100%" id="quick-sale">
                        <thead>
                        <tr>
                            <th class="ios"></th>
                            <th scope="col" sortable="order_number" (sort)="onSort($event)">№</th>
                            <th scope="col" sortable="phone" width="10%"
                                (sort)="onSort($event)">{{'REPORT_EDITED_HISTORY.PHONE' | translate}}</th>
                            <th scope="col" sortable="futuretime" width="10%"
                                (sort)="onSort($event)">{{'QUICK-SALE.TIME' | translate}}</th>
                            <th class="d-none" scope="col" sortable="futuredate" width="10%"
                                (sort)="onSort($event)">{{'GLOBAL.DATE' | translate}}</th>
                            <th scope="col" sortable="district"
                                (sort)="onSort($event)" width="15%"
                                *ngIf="!cashier">{{'QUICK-SALE.DISTRICT' | translate}}</th>
                            <th scope="col" sortable="street"
                                (sort)="onSort($event)"
                                [ngClass]="{'thStreet': !cashier, 'thPickup': cashier}">{{'QUICK-SALE.STREET' | translate}}</th>
                            <th scope="col" sortable="total"
                                (sort)="onSort($event)">{{'GLOBAL.SUM' | translate}}</th>
                            <th scope="col" *ngIf="cashier">{{'QUICK_SALE-SALE.PAYMENT_FORM' | translate}}</th>
                            <th scope="col" sortable="comment"
                                (sort)="onSort($event)">{{'GLOBAL.COMMENT' | translate}}</th>
                            <th scope="col" sortable="place_name"
                                (sort)="onSort($event)">{{'GLOBAL.PLACE' | translate}}</th>
                            <th scope="col" *ngIf="cashier">{{'GLOBAL.TODO' | translate}}</th>
                            <th scope="col" *ngIf="cashier">Касир</th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>

                        <tr
                                *ngFor="let order of countries$ | async; let i = index" [contextMenu]="basicMenu"
                                (dblclick)="openInNewTab('edit', order.id)"
                                [contextMenuSubject]="order"
                                [ngClass]="{'table-danger': order.status == 2 || order.status == 3 || order.status == 0}"
                        >
                            <td (click)="contextMenu(basicMenuIos, order.id, order.order_number, order.author_name, order.author_surname, order.status, order.city_id, order.phone, order.checkbox_id, order.checkbox_status)" class="ios">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-list" viewBox="0 0 16 16">
                                    <path fill-rule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"/>
                                </svg>
                            </td>
                            <td><span ngbTooltip="{{order.order_number}}"
                                      [placement]="(i < (service.pageSize - 5)) ? 'bottom' : 'top'"><ngb-highlight
                                    [result]="order.order_number | number"
                                    [term]="service.searchTerm"></ngb-highlight></span>
                            </td>
                            <td class="ellipsis first"><span ngbTooltip="{{order.phone | translate}}"
                                                             [placement]="(i < (service.pageSize - 5)) ? 'bottom' : 'top'"><ngb-highlight
                                    [result]="order.phone.toString() | translate" [term]="service.searchTerm"></ngb-highlight></span>
                            </td>
                            <td class="ellipsis first"><span ngbTooltip="{{order.futuretime}}"
                                                             [placement]="(i < (service.pageSize - 5)) ? 'bottom' : 'top'">
                                {{order.futuretime}}</span>
                            </td>
                            <td class="ellipsis first d-none"><span ngbTooltip="{{order.futuredate}}"
                                                             [placement]="(i < (service.pageSize - 5)) ? 'bottom' : 'top'">
                                {{order.futuredate}}</span>
                            </td>
                            <td class="ellipsis first" *ngIf="!cashier"><span ngbTooltip="{{order.district}}"
                                                                              [placement]="(i < (service.pageSize - 5)) ? 'bottom' : 'top'">
                                {{order.district}}</span>
                            </td>
                            <td class="ellipsis first"><span *ngIf="order.pickupcheck !== 1"
                                                             ngbTooltip="{{order.full_address}}
                /{{order.apt}}" [placement]="(i < (service.pageSize - 5)) ? 'bottom' : 'top'"><ngb-highlight
                                    [result]="order.full_address"
                                    [term]="service.searchTerm"></ngb-highlight></span>
                                <span *ngIf="order.pickupcheck == 1"
                                      ngbTooltip="{{order.street}}
                /{{order.apt}}" [placement]="(i < (service.pageSize - 5)) ? 'bottom' : 'top'"><ngb-highlight
                                        [result]="order.street" [term]="service.searchTerm"></ngb-highlight></span>
                            </td>
                            <td><span ngbTooltip="{{TotalWithRound(order.total)}}"
                                      [placement]="(i < (service.pageSize - 5)) ? 'bottom' : 'top'"><ngb-highlight
                                    [result]="TotalWithRound(order.total) | number"
                                    [term]="service.searchTerm"></ngb-highlight></span></td>
                            <td *ngIf="cashier">
                                <span *ngIf="order.terminalcheck">{{'QUICK-SALE.CASHLESS' | translate}}</span>
                                <span *ngIf="!order.terminalcheck">{{'QUICK-SALE.CASH' | translate}}</span>
                            </td>
                            <td class="ellipsis first"><span ngbTooltip="{{order.comment}}"
                                                             [placement]="(i < (service.pageSize - 5)) ? 'bottom' : 'top'"><ngb-highlight
                                    [result]="order.comment" [term]="service.searchTerm"></ngb-highlight></span>
                            </td>
                            <td class="ellipsis first"><span ngbTooltip="{{order.place_name}}"
                                                             [placement]="(i < (service.pageSize - 5)) ? 'bottom' : 'top'">{{order.place_name}}</span>
                            </td>
                            <td>
                                <button class="btn btn-outline-secondary btn-sm"
                                        *ngIf="cashier && order.pickup_pay == 0 && (order.status == 1 || order.status == 4)"
                                        [disabled]="!shiftCollectionId"
                                        (click)="applyStatus(order.id, checkBoxPrint)">Сплатити
                                </button>
                                <span *ngIf="cashier && order.pickup_pay != 0 && (order.status == 1 || order.status == 4)">{{'QUICK_SALE.PAYED' | translate}}</span>
                            </td>
                            <td *ngIf="cashier">
                                <span *ngIf="order.cashierInfo">{{order.cashierInfo.name}} {{order.cashierInfo.surname}}</span>
                            </td>
                            <td>
                                <svg *ngIf="order.checkbox_need_send == true" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-exclamation-square-fill" viewBox="0 0 16 16">
                                    <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm6 4c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995A.905.905 0 0 1 8 4zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"/>
                                </svg>
                                <svg *ngIf="order.checkbox_need_send == false" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-square" viewBox="0 0 16 16">
                                    <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"/>
                                    <path d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.235.235 0 0 1 .02-.022z"/>
                                </svg>


                                <svg *ngIf="order.quality_recall?.status === 'done'"  xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-telephone ml-2" viewBox="0 0 16 16">
                                    <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.6 17.6 0 0 0 4.168 6.608 17.6 17.6 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.68.68 0 0 0-.58-.122l-2.19.547a1.75 1.75 0 0 1-1.657-.459L5.482 8.062a1.75 1.75 0 0 1-.46-1.657l.548-2.19a.68.68 0 0 0-.122-.58zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.68.68 0 0 0 .178.643l2.457 2.457a.68.68 0 0 0 .644.178l2.189-.547a1.75 1.75 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.6 18.6 0 0 1-7.01-4.42 18.6 18.6 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877z"/>
                                </svg>

                                <svg *ngIf="order.quality_recall?.status === 'rejected' || order.quality_recall === null" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-telephone-x-fill ml-2" viewBox="0 0 16 16">
                                    <path fill-rule="evenodd" d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.68.68 0 0 0 .178.643l2.457 2.457a.68.68 0 0 0 .644.178l2.189-.547a1.75 1.75 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.6 18.6 0 0 1-7.01-4.42 18.6 18.6 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877zm9.261 1.135a.5.5 0 0 1 .708 0L13 2.793l1.146-1.147a.5.5 0 0 1 .708.708L13.707 3.5l1.147 1.146a.5.5 0 0 1-.708.708L13 4.207l-1.146 1.147a.5.5 0 0 1-.708-.708L12.293 3.5l-1.147-1.146a.5.5 0 0 1 0-.708"/>
                                </svg>

                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>


                <form style="padding-top: 1rem">
                    <div class="row">
                        <div class="col">
                            <ngb-pagination
                                    [collectionSize]="(total$ | async)!" [maxSize]="5" [(page)]="service.page"
                                    [pageSize]="service.pageSize">
                            </ngb-pagination>
                        </div>
                        <div class="col">
                            <ng-container *ngIf="service.loader$ | async;else loaded">
                                <button class="btn btn-primary" type="button" disabled style="width: 100%">
                                    <span class="spinner-border spinner-border-sm" role="status"
                                          aria-hidden="true"></span>
                                </button>
                            </ng-container>
                            <ng-template #loaded>
                                <button class="btn btn-primary" type="button" disabled>
                                    {{'QUICK_SALE.DOWNLOADED' | translate}}
                                </button>
                            </ng-template>
                        </div>
                        <div class="col">
                            <button class="btn btn-outline-primary btn-sm" (click)="updateList()"
                                    style="height: 38px;float: right"
                                    [disabled]="service.loader$ | async">{{'GLOBAL.UPDATE' | translate}}</button>
                        </div>
                        <div class="col">
                            <select class="form-control" [(ngModel)]="service.placeFromSelect"
                                    [ngModelOptions]="{standalone: true}" (ngModelChange)="changePlace()">
                                <option *ngFor="let object of DocService.places; i as index"
                                        [value]="object.id">{{object.description.name}}</option>
                                <option [value]="null">{{'GLOBAL.ALL' | translate}}</option>
                            </select>
                        </div>
                        <div class="col">
                            <select class="custom-select" id="pageSize" style="width: auto; float: right"
                                    name="pageSize"
                                    [(ngModel)]="service.pageSize">
                                <option [ngValue]="10">{{'QUICK_SALE.PER' | translate}} 10</option>
                                <option [ngValue]="25">{{'QUICK_SALE.PER' | translate}} 25</option>
                                <option [ngValue]="50">{{'QUICK_SALE.PER' | translate}} 50</option>
                                <option [ngValue]="100">{{'QUICK_SALE.PER' | translate}} 100</option>
                                <option [ngValue]="250">{{'QUICK_SALE.PER' | translate}} 250</option>
                                <option [ngValue]="999999999999999999999">{{'QUICK_SALE.ALL' | translate}}</option>
                            </select>
                        </div>
                    </div>
                </form>

            </div>
            <div class="col-sm-3">
                <div class="text-center d-flex justify-content-center">
                    <button class="btn btn-sm btn-outline-primary" (click)="openHistory()">{{'QUICK_SALE.ORDER_HISTORY' | translate}}</button>
                    <button
                            class="delays btn btn-sm btn-outline-success ml-2 position-relative"
                            (click)="openDelays()">
                        {{ 'QUICK_SALE.DELAYS_POSSIBLE' | translate }}
                        <span
                                *ngIf="isDelayRecallEmpty && possibleDelays.length > 0"
                                class="badge-check-count position-absolute top-0 end-0">
                                {{ possibleDelays.length }}
                        </span>
                    </button>
                </div>
                <div style="height: 70vh; overflow-y: scroll; overflow-x: hidden">
                    <h3 class="table thead-light"
                        style="text-align: center; padding-top: .3rem; vertical-align: middle">{{'QUICK_SALE.CALLS_FROM_THE_SITE' | translate}}</h3>
                    <ng-container *ngFor="let object of service.callsFromWebSite">
                        <div class="card " *ngIf="object.status == 'new' || object.status == 'pending'"
                             style="margin-bottom: .5rem;">
                            <div class="card-header d-flex align-items-center justify-content-center flex-column" [ngClass]="{'sushiOrder': object.status == 'new', 'pizzaOrder': object.status == 'pending'}">
                                   <p class="mr-2">{{object.city_name}}</p>
                                <div class="d-flex align-items-center">
                                    <p class="m-0 mr-2">{{object.phone}}</p>
                                    <a href="tel:{{object.phone}}" class="btn btn-sm btn-primary mr-2" (click)="putCallBackRequest(orderStatusEnum.Pending, object.id)">☏</a>
                                    <a *ngIf="object.status == 'pending'" class="btn btn-sm btn-success mr-2" (click)="putCallBackRequest(orderStatusEnum.Done, object.id)">✔</a>
                                    <a *ngIf="object.status == 'pending'" class="btn btn-sm btn-danger" (click)="putCallBackRequest(orderStatusEnum.Canceled, object.id)">✖</a>
                                </div>
                            </div>
                            <div class="text-center">
                                <hr>
                                <span>{{object.created_at | date:'dd.MM.yyyy HH:mm'}}</span>
                            </div>
                        </div>
                    </ng-container>
                    <h3 class="table thead-light"
                        style="text-align: center; padding-top: .3rem; vertical-align: middle">{{'QUICK_SALE.ORDER_FROM_SITE' | translate}}</h3>
                    <ng-container *ngFor="let object of service.ordersFromWebSite">
                        <div class="card text-center" *ngIf="object.state == 'pending' || object.state == 'processing'"
                             style="margin-bottom: .5rem;">
                            <div class="card-header" [ngClass]="{'sushiOrder': object.source == 'sushi', 'pizzaOrder': object.source == 'pizza'}">
                                <p>{{object.city_name}}</p>
                                {{object.address}}
                                <span *ngIf="object.address == null">
                                    {{'QUICK_SALE.PICKUP' | translate}}
                                </span>
                                <span *ngIf="object.house != null">{{object.house}}</span>
                                <span *ngIf="object.apt != null">, {{'QUICK-SALE.APARTMENT' | translate}} {{object.apt}}</span>
                            </div>
                            <div class="card-body" [ngClass]="{'processing-bg': object.state == 'processing'}">
                                <small *ngIf="object.comment">{{object.comment}}</small>
                                <small *ngIf="!object.comment">{{'QUICK_SALE.EMPTY_COMMENT' | translate}}</small>
                                <hr>
                                <small>{{object.updated_at}}</small>
                                <hr>
                                <small>{{object.phone}}</small>
                                <hr>
                                <small *ngIf="object.payment_type == 'online'">{{'QUICK-SALE.LIQPAY' | translate}} ({{liqpayStatus(object?.liqpay_payment_status)}})
                                </small>
                                <small *ngIf="object.payment_type == 'cash'">{{'QUICK-SALE.CASH' | translate}}</small>
                                <small *ngIf="object.payment_type == 'card'">{{'QUICK-SALE.TERMINAL' | translate}}</small>
                                <hr>
                                <small>ID: {{object.id}}</small>
                            </div>
                            <div class="card-footer text-muted">
                                <button class="btn btn-primary btn-sm" *ngIf="object.state === 'pending'"
                                        (click)="checkCurrentState(object.id, object.city_id, object.place_id, orderDone, orderCancelled, WarningAboutOrder)">{{'GLOBAL.APPLY' | translate}}</button>
                                <button class="btn btn-primary btn-sm"
                                        (click)="open(WarningAboutOrder, object.id, object.city_id, object.place_id)"
                                        *ngIf="object.state === 'processing'">{{'GLOBAL.APPLY' | translate}}</button>
                                <button class="btn btn-danger btn-sm"
                                        style="margin-left: .3rem"
                                        (click)="openModal(confirm, object.id, object.address ,object.phone, object.created_at)">{{'GLOBAL.BUTTONDELETE' | translate}}</button>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
    </form>


    <context-menu #basicMenu>
        <ng-template contextMenuItem let-order>
            <button class="btn btn-outline-success btn-sm w-100 border-0 " disabled>
                ID:{{order.id}}/№{{order.order_number}}
            </button>
            <button class="btn btn-outline-success btn-sm w-100 border-0 " disabled>
                {{order.author_name}} {{order.author_surname}}
            </button>
            <button class="btn btn-outline-success w-100" (click)="openInNewTab('copy', order.id)"
                    style="border-radius: 0; border: 0">
                {{'GLOBAL.COPY' | translate}}
            </button>
            <hr *ngIf="order.status !== 0">

            <button class="btn btn-outline-success w-100" style="border-radius: 0; border: 0" *ngIf="order.status !== 0"
                    (click)="deleteOrderConfirm(order.id, deleteOrderModalConfirm)">
                {{'GLOBAL.BUTTONDELETE' | translate}}
            </button>

            <hr *ngIf="order.status !== 0">
            <button class="btn btn-outline-success w-100" *ngIf="order.status !== 0" style="border-radius: 0; border: 0"
                    (click)="onPrint(order.id, receipt)">
                {{'GLOBAL.PRINT' | translate}}
            </button>
            <hr>
            <button class="btn btn-outline-success w-100" style="border-radius: 0; border: 0"
                    (click)="listeningBinotel(order.city_id, order.phone, binotelRecords, 'xl', order.order_number)">
                {{'QUICK_SALE.LISTEN_CONVERSATIONS' | translate}}
            </button>
            <hr *ngIf="order.status !== 0">
            <button class="btn btn-outline-success w-100" *ngIf="order.status !== 0" style="border-radius: 0; border: 0"
                    (click)="orderStatus(order.id, deliveryStatus, order.order_number)">
                {{'QUICK_SALE.TRACKING_ORDER' | translate}}
            </button>
            <hr *ngIf="(order.status !== 0 && order.checkbox_status === 'ERROR') || (order.status !== 0 && order.checkbox_status === null && order.checkbox_need_send == true)">
            <button class="btn btn-outline-success w-100" *ngIf="(order.status !== 0 && order.checkbox_status === 'ERROR') || (order.status !== 0 && order.checkbox_status === null && order.checkbox_need_send == true)" style="border-radius: 0; border: 0"
            (click)="sendRRO(order.id, checkBoxPrintByOrder, order.order_number)">
                {{'QUICK_SALE.RESHIPMENT_PRO' | translate}}
            </button>
            <hr *ngIf="order.checkbox_status === 'DONE'">
            <button class="btn btn-outline-success w-100" *ngIf="order.status !== 0 && order.checkbox_status === 'DONE'" style="border-radius: 0; border: 0"
            (click)="printCheckBox(order.checkbox_id, checkBoxPrintByOrder,  order.order_number)">
                {{'QUICK_SALE.REPRINT' | translate}}
            </button>
            <hr *ngIf="order.checkbox_status === 'CREATED'">
            <button class="btn btn-outline-success w-100" *ngIf="order.status !== 0 && order.checkbox_status === 'CREATED'" style="border-radius: 0; border: 0"
                    (click)="service.checkStatusRRO(checkBoxSendStatus, order.checkbox_id)">
                {{'QUICK_SALE.UPDATE_STATUS_PRO' | translate}}
            </button>
            <hr>
            <button class="btn btn-outline-success w-100" style="border-radius: 0; border: 0"
                    (click)="openQualityControl(order, qualityControl)">
                {{ 'QUICK_SALE.QUALITY_CONTROL_CALL' | translate }}
            </button>
        </ng-template>
    </context-menu>


</div>


<ng-template #reportOperators let-modal>
    <form id="formalert" ngNoForm action="https://crm3303.co.ua/quicksale/htmloperators" method="post" target="_blank">
        <div class="modal-header">
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <input type="date" id="date" class="form-control" name="date" required>
            <input type="hidden" name="place" value="[{{place}}]">
        </div>
        <div class="modal-footer">
            <input type="submit" class="btn btn-outline-success" (click)="modal.close('Save click')" value="Save">
        </div>
    </form>
</ng-template>


<ng-template #reportQuicksale let-modal>
    <form ngNoForm action="https://crm3303.co.ua/quicksale/html/" method="post" target="_blank">
        <div class="modal-header">
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">

            <div class="form-group ml-2">

                <div class="input-group">
                    <div class="input-group-prepend">
                        <span class="input-group-text">З:</span>
                    </div>
                    <input type="date" id="date_start" class="form-control" name="date_start" required>
                </div>

            </div>
            <div class="form-group ml-2">

                <div class="input-group">
                    <div class="input-group-prepend">
                        <span class="input-group-text">До:</span>
                    </div>
                    <input type="date" id="date_end" class="form-control" name="date_end" required>
                </div>

            </div>
            <select multiple class="form-control" name="place[]">
                <option *ngFor="let object of DocService.places; i as index"
                        [value]="object.id">{{object.description.name}}</option>
            </select>
        </div>
        <div class="modal-footer">
            <input type="submit" class="btn btn-outline-success" (click)="modal.close('Save click')" value="Save">
        </div>
    </form>
</ng-template>


<ng-template #WarningAboutOrder let-modal>
    <div class="modal-header">
        <h4 class="modal-title">{{'QUICK_SALE.WARNING' | translate}}</h4>
    </div>
    <div class="modal-footer">
        <button class="btn btn-primary btn-sm"
                (click)="checkCurrentState(idWS, cityLocal, place, orderDone, orderCancelled, null, 'open')">{{'GLOBAL.APPLY' | translate}}</button>
    </div>
</ng-template>


<ng-template #ReportQuickSale let-modal>
    <div class="modal-header">
        <h4 class="modal-title">{{'QUICK_SALE.REPORT_QS' | translate}}</h4>
        <button type="button" class="close" aria-label="Close" (click)="modalService.dismissAll()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">

        <form class="form-inline mb-2">
            <div class="form-group hidden">
                <div class="input-group">
                    <input name="datepicker"
                           class="form-control"
                           ngbDatepicker
                           #datepicker="ngbDatepicker"
                           [autoClose]="'outside'"
                           (dateSelect)="onDateSelection($event, datepicker)"
                           [displayMonths]="2"
                           [dayTemplate]="t"
                           outsideDays="hidden"
                           [startDate]="fromDateModal!"
                           tabindex="-1">
                    <ng-template #t let-date let-focused="focused">
        <span class="custom-day"
              [class.focused]="focused"
              [class.range]="isRange(date)"
              [class.faded]="isHovered(date) || isInside(date)"
              (mouseenter)="hoveredDate = date"
              (mouseleave)="hoveredDate = null">
          {{ date.day }}
        </span>
                    </ng-template>
                </div>
            </div>
            <div class="form-group">
                <div class="input-group">
                    <input #dpFromDate
                           class="form-control" placeholder="yyyy-mm-dd"
                           name="dpFromDate"

                           [value]="formatter.format(fromDateModal)"
                           (input)="fromDate = validateInput(fromDateModal, dpFromDate.value)">
                    <div class="input-group-append">
                        <button class="btn btn-outline-success calendar" (click)="datepicker.toggle()"
                                type="button">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                 class="bi bi-calendar2-date" viewBox="0 0 16 16">
                                <path fill-rule="evenodd"
                                      d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM2 2a1 1 0 0 0-1 1v11a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1H2z"/>
                                <path
                                        d="M2.5 4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5H3a.5.5 0 0 1-.5-.5V4zm3.945 8.688V7.354h-.633A12.6 12.6 0 0 0 4.5 8.16v.695c.375-.257.969-.62 1.258-.777h.012v4.61h.675zm1.188-1.305c.047.64.594 1.406 1.703 1.406 1.258 0 2-1.066 2-2.871 0-1.934-.781-2.668-1.953-2.668-.926 0-1.797.672-1.797 1.809 0 1.16.824 1.77 1.676 1.77.746 0 1.23-.376 1.383-.79h.027c-.004 1.316-.461 2.164-1.305 2.164-.664 0-1.008-.45-1.05-.82h-.684zm2.953-2.317c0 .696-.559 1.18-1.184 1.18-.601 0-1.144-.383-1.144-1.2 0-.823.582-1.21 1.168-1.21.633 0 1.16.398 1.16 1.23z"/>
                            </svg>
                        </button>
                    </div>
                </div>
            </div>

            <div class="form-group ml-2">
                <div class="input-group w-100">
                    <input #dpToDate
                           class="form-control" placeholder="yyyy-mm-dd"
                           name="dpToDate"
                           [value]="formatter.format(toDate)"
                           (input)="toDate = validateInput(toDate, dpToDate.value)">
                </div>
            </div>
        </form>

        <div>
            <label>{{'QUICK_SALE.PLACES' | translate}}:</label>
            <select class="form-control" [(ngModel)]="placeForReport" [ngModelOptions]="{standalone: true}">
                <option [value]="null">{{'QUICK_SALE.ALL' | translate}}</option>
                <option *ngFor="let object of DocService.places; i as index"
                        [value]="object.id">{{object.description.name}}</option>
            </select>
        </div>

        <div>
            <label class="mt-2">{{'QUICK_SALE.ORGANIZATION' | translate}}:</label>
            <select class="form-control" [(ngModel)]="checkboxOrganizationId" [ngModelOptions]="{standalone: true}">
                <option [value]="null">{{'QUICK_SALE.ALL' | translate}}</option>
                <ng-container  *ngFor="let object of checkboxOrganization; i as index">
                    <option *ngIf="object.place.city_id === +cityLocal" [value]="object.id">
                            {{object.name}}
                    </option>
                </ng-container>


            </select>
        </div>

    </div>
    <div class="modal-footer">
        <button class="btn btn-outline-success" [disabled]="!placeForReport && !fromDateModal && !toDate"
                (click)="reportSale(ReportSale)">{{'QUICK_SALE.FORM' | translate}}</button>
    </div>
</ng-template>

<ng-template #ReportSale let-modal>
    <div class="modal-header">
        <h4 class="modal-title">{{'QUICK_SALE.REPORT_QS' | translate}}</h4>
        <button type="button" class="close" aria-label="Close" (click)="modalService.dismissAll()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <app-report-quick-sale [place]="placeForReport" [checkboxOrganizationId]="checkboxOrganizationId" [fromDate]="fromDateModal"
                               [toDate]="toDate"></app-report-quick-sale>
    </div>
    <div class="modal-footer">
    </div>
</ng-template>


<ng-template #deleteOrderModal let-modal>
    <div class="modal-header">
        <h4 class="modal-title">{{'QUICK_SALE.REASON_DELETE' | translate}}</h4>
    </div>
    <div class="modal-body">
        <div class="form-row">
            <div class="form-group col-sm-12">
                        <textarea class="form-control" formControlName="comment"
                                  placeholder="Вкажіть причину видалення цього чеку" id="comments"
                                  style="height: 10vh"></textarea>
            </div>
        </div>
    </div>
    <div class="modal-footer">
    </div>
</ng-template>


<ng-template #deleteOrderModalConfirm let-modal>
    <div class="modal-body">
        <p class="font-weight-bold text-center"> {{ 'QUICK_SALE.ARE_YOU_SURE_YOU_WANT_TO_DELETE_THE_ORDER' | translate }} </p>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-success" (click)="deleteOrder(); modal.close()">{{ 'QUICK_SALE.YES' | translate }}</button>
        <button type="button" class="btn btn-outline-danger" (click)="modal.dismiss()">{{ 'QUICK_SALE.NO' | translate }}</button>
    </div>
</ng-template>

<ng-template #qualityControl>
        <app-quality-control
            [orderInfo]="orderInfo"
        ></app-quality-control>
</ng-template>

<ng-template #receipt let-modal>
    <div class="modal-header">
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <app-recipt orderId="{{orderId}}"></app-recipt>
    </div>
    <div class="modal-footer">
        <button
                class="btn btn-outline-success"
                ngxPrint
                printTitle="{{'GLOBAL.PRINT_REPORT' | translate}}"
                printSectionId="receipt"
                [useExistingCss]="false"
        >{{'GLOBAL.PRINT' | translate}}</button>
    </div>
</ng-template>

<ng-template #confirm>
    <div class="modal-body">
        <h2 class="text-center">{{'QUICK_SALE.WARNING_DELETE' | translate}}</h2>
        <div class="text-center">
            <p>{{address}}</p>
        <p>{{phone}}</p>
        <p>{{time}}</p>
        </div>
    </div>
    <div class="modal-footer justify-content-center">
        <button type="button" class="btn btn-outline-success w-25" (click)="confirmModal()">{{'QUICK_SALE.YES' | translate}}</button>
        <button type="button" class="btn btn-danger w-25" (click)="closeModal()">{{'QUICK_SALE.NO' | translate}}</button>
    </div>
</ng-template>

<ng-template #deliveryStatus>
    <div class="modal-body">
        <h2 class="text-center">{{'QUICK_SALE.TRACKING_ORDER' | translate}} № {{deliveryStatusId}}</h2>
        <div class="text-center">
            <p>{{'QUICK_SALE.ESTIMATED_DELIVERY_TIME' | translate}}: {{orderDeliveryStatus.delivery_time}}</p>
            <p *ngIf="orderDeliveryStatus.status == 2">{{'QUICK_SALE.STATUS' | translate}}: {{'QUICK_SALE.ACCEPTED' | translate}}</p>
            <p *ngIf="orderDeliveryStatus.status == 3">{{'QUICK_SALE.STATUS' | translate}}: {{'QUICK_SALE.PREPARED' | translate}}</p>
            <p *ngIf="orderDeliveryStatus.status == 4">{{'QUICK_SALE.STATUS' | translate}}: {{'QUICK_SALE.COURIER_TAKE' | translate}}</p>
            <p *ngIf="orderDeliveryStatus.status == 5">{{'QUICK_SALE.STATUS' | translate}}: {{'QUICK_SALE.DELIVERY' | translate}}</p>
            <p *ngIf="orderDeliveryStatus.status == 6">{{'QUICK_SALE.STATUS' | translate}}: {{'QUICK_SALE.DELIVERED' | translate}}</p>
            <p *ngIf="orderDeliveryStatus.status == 5">{{'QUICK_SALE.COURIER_NAME' | translate}}: {{orderDeliveryStatus.courier_name}}</p>
            <p *ngIf="orderDeliveryStatus.status == 5">{{'QUICK_SALE.COURIER_PHONE' | translate}}: {{orderDeliveryStatus.courier_phone}}</p>

        </div>
    </div>
    <div class="modal-footer justify-content-center">
        <button type="button" class="btn btn-success w-25" (click)="closeModal()">Ок</button>
    </div>
</ng-template>

<ng-template #binotelRecords>
    <div class="modal-header text-center">
        <span class="h3">{{'QUICK_SALE.LISTEN_ORDER' | translate}} № {{deliveryStatusId}}</span>
    </div>
    <div class="modal-body">
        <table class="table">
            <thead>
            <tr>
                <td>{{'QUICK_SALE.PHONE' | translate}}</td>
                <td>{{'QUICK_SALE.DATETIME' | translate}}</td>
                <td>{{'QUICK_SALE.CALL_TYPE' | translate}}</td>
                <td>{{'QUICK_SALE.STATUS' | translate}}</td>
                <td>{{'QUICK_SALE.DURATION' | translate}}</td>
                <td>{{'QUICK_SALE.ACTION' | translate}}</td>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let orders of binotel">
                <td>{{orders.externalNumber}}</td>
                <td>{{orders.startTime}}</td>
                <td>{{orders.callType}}</td>
                <td>{{orders.disposition}}</td>
                <td>{{orders.billsec}} c</td>
                <td>
                    <button [disabled]="orders.url == null" class="btn btn-success" (click)="openNewWindow(orders.url)">{{'QUICK_SALE.LISTEN' | translate}}</button>
                </td>
            </tr>
            </tbody>
        </table>
    </div>
    <div class="modal-footer">
        <button class="btn btn-success" (click)="closeModal()">Ок</button>
    </div>
</ng-template>

<ng-template #cities>
    <div class="modal-header text-center">
        <span class="h3">{{'QUICK_SALE.CHOOSE_CITY' | translate}}</span>
    </div>
    <div class="modal-body">
        <div class="row">
            <ng-container *ngFor="let city of citiesCallCenter.cities">
            <div class="col-6 text-center">
                <button class="btn btn-outline-success mb-4" (click)="openInNewTab('new', '', city.id, city.places[0].id)">{{city?.description?.name}}</button>
            </div>
            </ng-container>
        </div>
    </div>
    <div class="modal-footer">
        <button class="btn btn-danger" (click)="closeModal()">{{'QUICK_SALE.CLOSE' | translate}}</button>
    </div>
</ng-template>


<ng-template #orderDone>
    <div class="modal-body text-center">
        <span class="h5 font-weight-bold">{{'QUICK_SALE.ORDER_ACCEPTED' | translate}}</span>
    </div>
    <div class="modal-footer">
        <button class="btn btn-danger" (click)="closeModal()">{{'QUICK_SALE.CLOSE' | translate}}</button>
    </div>
</ng-template>

<ng-template #orderCancelled>
    <div class="modal-body text-center">
        <span class="h5 font-weight-bold">{{'QUICK_SALE.ORDER_DELETED' | translate}}</span>
    </div>
    <div class="modal-footer">
        <button class="btn btn-danger" (click)="closeModal()">{{'QUICK_SALE.CLOSE' | translate}}</button>
    </div>
</ng-template>

<ng-template #basicMenuIos>
    <button class="btn btn-outline-success btn-sm w-100 border-0 " disabled>
        ID:{{order.id}}/№{{order.order_number}}
    </button>
    <button class="btn btn-outline-success btn-sm w-100 border-0 " disabled>
        {{order.author_name}} {{order.author_surname}}
    </button>
    <button class="btn btn-outline-success w-100" (click)="openInNewTab('copy', order.id)"
            style="border-radius: 0; border: 0">
        {{'GLOBAL.COPY' | translate}}
    </button>
    <hr>
    <button class="btn btn-outline-success w-100" style="border-radius: 0; border: 0" *ngIf="order.status !== 0"
            (click)="deleteOrderConfirm(order.id, deleteOrderModal)">
        {{'GLOBAL.BUTTONDELETE' | translate}}
    </button>
    <hr *ngIf="order.status !== 0">
    <button class="btn btn-outline-success w-100" *ngIf="order.status !== 0" style="border-radius: 0; border: 0"
            (click)="onPrint(order.id, receipt)">
        {{'GLOBAL.PRINT' | translate}}
    </button>
    <hr>
    <button class="btn btn-outline-success w-100"  style="border-radius: 0; border: 0"
            (click)="listeningBinotel(order.city_id, order.phone, binotelRecords, 'xl', order.order_number)">
        {{'QUICK_SALE.LISTEN_CONVERSATIONS' | translate}}
    </button>
    <hr>
    <button class="btn btn-outline-success w-100" *ngIf="order.status !== 0" style="border-radius: 0; border: 0"
            (click)="orderStatus(order.id, deliveryStatus, order.order_number)">
        {{'QUICK_SALE.TRACKING_ORDER' | translate}}
    </button>
    <hr *ngIf="order.checkbox_status === 'ERROR'">
    <button class="btn btn-outline-success w-100" *ngIf="order.status !== 0 && order.checkbox_status === 'ERROR'" style="border-radius: 0; border: 0"
    (click)="sendRRO(order.id, checkBoxPrintByOrder, order.order_number)">
        {{'QUICK_SALE.RESHIPMENT_PRO' | translate}}
    </button>
    <hr *ngIf="order.checkbox_status === 'DONE'">
    <button class="btn btn-outline-success w-100" *ngIf="order.status !== 0 && order.checkbox_status === 'DONE'" style="border-radius: 0; border: 0"
            (click)="printCheckBox(order.checkbox_id, checkBoxPrintByOrder, order.order_number)">
        {{'QUICK_SALE.REPRINT' | translate}}
    </button>
    <hr *ngIf="order.checkbox_status === 'CREATED'">
    <button class="btn btn-outline-success w-100" *ngIf="order.status !== 0 && order.checkbox_status === 'CREATED'" style="border-radius: 0; border: 0"
            (click)="service.checkStatusRRO(checkBoxSendStatus, order.checkbox_id)">
        {{'QUICK_SALE.UPDATE_STATUS_PRO' | translate}}
    </button>
    <hr>
    <button class="btn btn-outline-success w-100" style="border-radius: 0; border: 0"
            (click)="openQualityControl(order, qualityControl)">
        {{ 'QUICK_SALE.QUALITY_CONTROL_CALL' | translate }}
    </button>
    <hr>
    <button class="btn btn-danger" (click)="close()">{{'QUICK_SALE.CLOSE' | translate}}</button>
</ng-template>

<ng-template #checkBoxPrint>
    <div id="checkbox" class="modal-body text-center">
       <img style="width: 400px" src="https://api.checkbox.in.ua/api/v1/receipts/{{service.checkBox.data.checkbox_id}}/png?width=32&paperWidth=80" alt="">
    </div>
    <div class="modal-footer">
        <button class="btn btn-danger" (click)="closeModal()">{{'QUICK_SALE.CLOSE' | translate}}</button>
        <button
                class="btn btn-outline-success"
                ngxPrint
                printTitle="{{'GLOBAL.PRINT_REPORT' | translate}}"
                printSectionId="checkbox"
                [useExistingCss]="false"
        >{{'GLOBAL.PRINT' | translate}}</button>
    </div>
</ng-template>

<ng-template #checkBoxPrintByOrder>
    <div id="checkboxByOrder" class="modal-body text-center">
        <p style="font-size: 28px">{{orderNumber}}</p>
        <img style="width: 400px" src="https://api.checkbox.in.ua/api/v1/receipts/{{idCheckBoxByOrder}}/png?width=32&paperWidth=80" alt="">
    </div>
    <div class="modal-footer">
        <button class="btn btn-danger" (click)="closeModal()">{{'QUICK_SALE.CLOSE' | translate}}</button>
        <button
                class="btn btn-outline-success"
                ngxPrint
                printTitle="{{'GLOBAL.PRINT_REPORT' | translate}}"
                printSectionId="checkboxByOrder"
                [useExistingCss]="false"
        >{{'GLOBAL.PRINT' | translate}}</button>
    </div>
</ng-template>

<ng-template #checkBoxSendStatus>
    <div id="checkboxSend" class="modal-body text-center">
        <img style="width: 400px" src="https://api.checkbox.in.ua/api/v1/receipts/{{service.checkBoxStatus.data.id}}/png?width=32&paperWidth=80" alt="">
    </div>
    <div class="modal-footer">
        <button class="btn btn-danger" (click)="closeModal()">{{'QUICK_SALE.CLOSE' | translate}}</button>
        <button
                class="btn btn-outline-success"
                ngxPrint
                printTitle="{{'GLOBAL.PRINT_REPORT' | translate}}"
                printSectionId="checkboxSend"
                [useExistingCss]="false"
        >{{'GLOBAL.PRINT' | translate}}</button>
    </div>
</ng-template>

<div class="loading" *ngIf="loader === true">Loading&#8230;</div>
<div class="loading" *ngIf="service.loader === true">Loading&#8230;</div>


import {Injectable} from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor
} from '@angular/common/http';
import {catchError} from 'rxjs/operators';
import {Observable, throwError} from 'rxjs';
import {Alltoasts} from '../toasts/alltoasts';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';


@Injectable()
export class HttpErrorsInterceptor implements HttpInterceptor {

    constructor(private AllToasts: Alltoasts,
                private router: Router,
                private translate: TranslateService
                ) {
    }

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        return next.handle(request).pipe(
            catchError(error => {
                if (error.status === 429){
                    localStorage.clear();
                    this.router.navigate(['/login']);
                }
                this.toastAboutError(error);
                return throwError(error);
            })
        );
    }

    private toastAboutError(error): void {
        switch (error.status) {
                case 403:
                    this.banError(error.error.message);
                    break;
                case 429:
                    break;
                case 400:
                    break;
                default:
                    if (!error.error.message) {
                        this.AllToasts.showDanger(this.translate.instant('HTTP-ERRORS.SORRY_COULD_NOT_COMPLETE_THE_REQUEST_PLEASE_TRY_AGAIN_LATER'));
                    }else {
                        this.AllToasts.showDanger(`${error.error.message}`);
                    }
                    break;
            }

    }

    private banError(message?: string): void{
        switch (message){
            case 'User not on shift':
                this.AllToasts.showDanger(this.translate.instant('HTTP-ERRORS.THIS_ACTION_IS_NOT_ALLOWED_REASON_USER_IS_NOT_ON_SHIFT'));
                break;
            default:
                this.AllToasts.showDanger(`${this.translate.instant('HTTP-ERRORS.THIS_ACTION_IS_NOT_ALLOWED_REASON')} ${message}`);
                break;
        }
    }
}

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule, Routes} from '@angular/router';
import {QuickSaleComponent} from './quick-sale.component';
import {TableCompleteComponent} from './all-quick-sale/all-quicksale.component';
import {DocumentComponent} from './document/document.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';
import {ContextMenuModule} from 'ngx-contextmenu';
import {QuickSaleSearchPipe} from './quick-sale-search.pipe';
import {SortableDirective} from './all-quick-sale/sortable.directive';
import {ClientCashAccountingComponent} from '../cash-accounting/client-cash-accounting/client-cash-accounting.component';
import {ReportQuickSaleComponent} from './report-quick-sale/report-quick-sale.component';
import {ReceiptComponent} from './receipt/receipt.component';
import {NgxPrintModule} from 'ngx-print';
import {MatBadgeModule} from '@angular/material/badge';
import {MatTooltipModule} from "@angular/material/tooltip";
import {NgSelectModule} from "@ng-select/ng-select";
import {sharedModule} from "../@shared/shared.module";
import {NgxQRCodeModule} from '@techiediaries/ngx-qrcode';
import { HistoryOrdersComponent } from './history-orders/history-orders.component';
import {HistoryOrdersService} from './history-orders/history-orders.service';
import { HistoryOrdersPipe } from './history-orders/history-orders.pipe';
import {CashAccountingCheckboxService} from "../cash-accounting-checkbox/service/cash-accounting-checkbox.service";
import {WinnerListService} from "../@shared/reports/winner-list.service";
import {QualityControlComponent} from "./all-quick-sale/quality-control/quality-control.component";
import {PossibleDelaysService} from "../possible-delays/service/possible-delays.service";


const quickSaleRoutes: Routes = [
        {
            path: '', component: QuickSaleComponent, children: [
                {path: 'receipt', component: ReceiptComponent},
                {path: 'history-orders', component: HistoryOrdersComponent},
                {path: '', component: TableCompleteComponent},
                {path: ':mode/:id', component: DocumentComponent},
                {path: ':mode/:id/:from', component: DocumentComponent},
                {path: ':mode', component: DocumentComponent},
            ],
        },
    ];

@NgModule({
    declarations: [
        QuickSaleComponent,
        TableCompleteComponent,
        DocumentComponent,
        QuickSaleSearchPipe,
        SortableDirective,
        ClientCashAccountingComponent,
        ReportQuickSaleComponent,
        ReceiptComponent,
        HistoryOrdersComponent,
        HistoryOrdersPipe,
        QualityControlComponent
    ],
    imports: [
        CommonModule,
        RouterModule.forChild(quickSaleRoutes),
        NgbModule,
        FormsModule,
        TranslateModule,
        ContextMenuModule,
        ReactiveFormsModule,
        NgxPrintModule,
        MatBadgeModule,
        MatTooltipModule,
        NgSelectModule,
        sharedModule,
        NgxQRCodeModule
    ],
    exports: [
        TableCompleteComponent,
        ClientCashAccountingComponent,
        QuickSaleComponent,
        ReceiptComponent
    ],
    providers: [
        HistoryOrdersService,
        WinnerListService,
        PossibleDelaysService,
        CashAccountingCheckboxService
    ]
})
export class QuickSaleModule {
}

import {Component, ElementRef, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {NgbCalendar, NgbDateParserFormatter, NgbModal, NgbModalConfig, NgbPopover} from '@ng-bootstrap/ng-bootstrap';
import {Router} from '@angular/router';
import {combineLatest, Observable} from 'rxjs';

import {RoutelistService} from '../../@shared/routelist/routelist.service';
import {EditPurposes, Routes} from './interface';
import {SortableDirective, SortEvent} from '../../quick-sale/all-quick-sale/sortable.directive';
import {NewDocumentService} from '../../@shared/quick-sale/new-document/new-document.service';
import {Terminal, TerminalState} from '../../_terminals/interfaces/terminals.interface';
import {TerminalsService} from '../../_terminals/services/terminals.service';
import {map, takeUntil} from 'rxjs/operators';
import {Alltoasts} from '../../toasts/alltoasts';
import { RouteListBuilderService } from 'src/app/@shared/@route-list-builder/route-list-builder.service';
import { Unsubscribe } from 'src/app/@shared/unsubscribe';
import {tileLayer, marker, icon, latLng, polyline} from 'leaflet';
import * as L from 'leaflet';
import { TranslateService } from '@ngx-translate/core';
import {Users} from "../../@shared/@users/interfaces/users.interface";
import {DatePipe} from "@angular/common";

@Component({
    selector: 'app-routelist',
    templateUrl: './routelist.component.html',
    styleUrls: ['./routelist.component.scss']
})
export class RoutelistComponent extends Unsubscribe implements OnInit, OnDestroy {

    constructor(public service: RoutelistService, private calendar: NgbCalendar,
                public formatter: NgbDateParserFormatter,
                private router: Router,
                public modalService: NgbModal,
                private settingNgM: NgbModalConfig,
                public quickSaleService: NewDocumentService,
                private terminalService: TerminalsService,
                private rlBuilderService: RouteListBuilderService,
                private Alltoasts: Alltoasts,    
                private translateService: TranslateService,
                private datePipe: DatePipe,
    ) {
        super()
        this.countries$ = service.orders$;
        this.total$ = service.total$;
        settingNgM.backdrop = 'static';
        settingNgM.keyboard = false;
    }

    type: string;
    countries$: Observable<Routes[]>;
    total$: Observable<number>;
    place: string = localStorage.getItem('place');
    index: number;
    orderNumber: number;
    popover: NgbPopover;
    prof: string = localStorage.getItem('profs');
    private allOrders;
    order =  {
        route_list_id: null,
        order_number: null
    };
    checkBox: any;
    idCheckBoxByOrder: string;
    checkBoxStatus: any;
    loader: boolean;
    map: L.Map;
    distance: number;
    couriers: Users[];
    users: Users[];
    CheckBoxOrderNumber: number;
    comment: string;
    purposeId: number;
    editPurposes: EditPurposes[];
    message = '';


    @ViewChildren(SortableDirective) headers: QueryList<SortableDirective>;
    @ViewChild('audioOption') audioPlayerRef: ElementRef;
    terminalId: number;
    terminals$: Observable<Terminal[]>;
    saveEditableRoute = () => {
        this.service.saveEditableRoute(this.index, this.comment, this.purposeId).subscribe(() => {
        }, () => {
        }, () => {
            this.allOrders = setInterval(() => {
                this.updateTable();
            }, 10000);
            this.modalService.dismissAll();
            this.comment = '';
        });
    };
    onSort = ({column, direction}: SortEvent) => {
        this.headers.forEach(header => {
            if (header.sortable !== column) {
                header.direction = '';
            }
        });
        this.service.sortColumn = column;
        this.service.sortDirection = direction;
    };

    checkOnOrders(object: Routes): number {
        return object.orders.filter(order => order.payment_type == '1').length;
    }

    openPopover(popover: NgbPopover, object: Routes, type: string): void {
        this.type = type;
        if (this.popover) {
            this.popover.close();
        }
        this.popover = popover;
        if (this.checkOnOrders(object) && object.pay_state !== 'done') {
            popover.open();
        } else if (type === 'debt')
        {
            this.toDebt(object.route_list_id);
        }
        else if (object.pay_state !== 'done' && type !== 'debt') {
            this.service.fetchAll()
                .pipe(takeUntil(this.$destroy))
                .subscribe({
                    complete: () => {
                        this.service.updatePaymentState(object.route_list_id).subscribe(
                            {
                                complete: () => popover.close()
                            }
                        );
                    },
                });

        }
    }

    changeIndex = (id, content) => {
        this.updateTable(id, content );
        this.getUsersCourier();
        this.getEditPurposes();
    }
    deleteFromRoute = (i) => {
        this.service.AllOrders[this.index].orders.splice(i, 1);
    };
    addToRoute = () => {
        this.service.addToRoute(this.orderNumber, this.index).subscribe();
    };
    open = content => {
        this.modalService.open(content, {size: 'xl'});
    };

    violationCount(order){
      return  order.orders.filter(el => el.violation == true).length
    }

    
    updatePaymentState = (route?: Routes) => {
        this.popover.close();
        const requests: Array<any> = route.orders.filter(t => t.terminal_paid == 0).map((order) => {
            const body = {
                terminal: this.terminalId
            };
            return this.quickSaleService.update(body, order.order_id);
        });
        combineLatest(requests).subscribe({
            complete: () => {
                if (this.type === 'debt') {
                    this.toDebt(route.route_list_id);
                } else {
                    this.service.updatePaymentState(route.route_list_id).subscribe();
                }
            }
        });
    };

    toDebt(id: number): void {
        this.service.createDebt(id)
            .subscribe({
                complete: () => this.updateTable()
            });
    }

    ngOnInit(): void {
        this.getTerminals();
        this.quickSaleService.getAllTerminals().subscribe();
        if (this.service.model.year !== undefined) {
            const orders = this.service.fetchAll().subscribe(() => {
            }, () => {
            }, () => {
                orders.unsubscribe();
            });
            this.allOrders = setInterval(() => {
                this.updateTable();
            }, 60000);
        } else {
            setTimeout(() => {
                this.ngOnInit();
            }, 1000);
        }
        this.listenerToUpdateRouteList()

    }

    listenerToUpdateRouteList(): void{
        this.rlBuilderService.needUpdateRouteList.pipe(takeUntil(this.$destroy)).subscribe(
            {
                next: value => {                    
                    if(value){
                        this.updateTable()    
                    }
                } 
            }
        )
    } 


    updateTable(id? , content?): void{
        this.service.fetchAll()
        .pipe(takeUntil(this.$destroy))
        .subscribe({
            complete: () => {
                if(id){
                    this.index = this.service.AllOrders.findIndex(t => t.route_list_id == id);
                    this.open(content);
                    clearInterval(this.allOrders); 
                }
            },
        });
      }

    printThisRoute = (id) => {
        this.service.fetchAll()
            .pipe(takeUntil(this.$destroy))
            .subscribe({
                complete: () => {
                    this.service.getInfoAboutRoute(id).subscribe(() => {
                    }, () => {
                    }, () => {
                    });
                },
            });
    }

    ngOnDestroy(): void {
        clearInterval(this.allOrders);
    }

    getTerminals(): void {
        this.terminals$ = this.terminalService.get()
            .pipe(map(element => element.filter(item => item.state === TerminalState.Active && item.place == Number(localStorage.getItem('place')))));
    }

    contextMenu(content, idCC, orderNumber): void {
        this.order.route_list_id = idCC;
        this.order.order_number = orderNumber;
        this.modalService.open(content, {size: 'xl'});
    }
    close(): void {
        this.modalService.dismissAll();
    }

    sendRRO(id, modal, orderNumber): void {
        this.loader = true;
        this.service.sendCheckBox(id).subscribe({
            next: value => this.checkBox = value,
            complete: () => {
                this.idCheckBoxByOrder = this.checkBox.data.id;
                this.CheckBoxOrderNumber = orderNumber;
                if (this.checkBox.data.status === 'CREATED') {
                    this.checkStatusRRO(modal);
                } else if (this.checkBox.data.status === 'DONE') {
                    this.loader = false;
                    this.modalService.open(modal);
                    this.updateTable();
                } else if (this.checkBox.data.status === 'ERROR') {
                    this.loader = false;
                    this.Alltoasts.showDanger('Виникла помилка, відправте повторно до РРО');
                }
            }
    });
    }

    printCheckBox(id, content, orderNumber): void {
        this.idCheckBoxByOrder = id;
        this.CheckBoxOrderNumber = orderNumber;
        this.modalService.open(content);
    }
    checkStatusRRO(modal): void {
        this.loader = true;
        this.service.StatusRRO(this.idCheckBoxByOrder).subscribe({
            next: value => this.checkBoxStatus = value,
            complete: () => {
                if (this.checkBoxStatus.data.status === 'CREATED') {
                    setTimeout(() => {
                        this.checkStatusRRO(modal);
                    }, 2000);
                } else if (this.checkBoxStatus.data.status === 'DONE') {
                    this.loader = false;
                    this.modalService.open(modal);
                    this.updateTable();
                } else if (this.checkBoxStatus.data.status === 'ERROR') {
                    this.loader = false;
                    this.Alltoasts.showDanger('Виникла помилка, відправте повторно до РРО');
                }
            }
        });
    }

    closeModalHandler(){
        this.service.AllOrders[this.index].orders.forEach((obj: any) => {
            obj.showAdditionalInfo = false;
          });
    }

    toggleAdditionalInfo(object: any) {
        this.service.AllOrders[this.index].orders.forEach((obj: any, index) => {
            if (obj !== object) {
                obj.showAdditionalInfo = false;
            }
            else {
                if(!obj.showAdditionalInfo ){
                    setTimeout(() => {
                        this.initializeMap();
                        this.addMarkers(index);            
                    }, 0); 
                }                
            }
        });
        object.showAdditionalInfo = !object.showAdditionalInfo;
        
    }

    

    initializeMap(): void {
        this.map = L.map('map').setView([47.8277464, 35.1562575], 13);
        tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
            attribution: 'OpenStreetMap'
        }).addTo(this.map);
    }

    addMarkers(i: number): void {

    const realPIN = this.translateService.instant('ROUTLISTEDIT.REALPOINT');
    const planPIN = this.translateService.instant('ROUTLISTEDIT.PLANPOINT');
    const order = this.service.AllOrders[this.index]?.orders[i];

        this.map.eachLayer(layer => {
            if (layer instanceof L.Marker) {
                this.map.removeLayer(layer);
            }
        });
        if (order) {
            const planCoordinates = order.plan_location;
            const realCoordinates = order.real_location;

            if (planCoordinates && realCoordinates) {
                const [plan_lat, plan_lng] = planCoordinates.split(',');
                const [real_lat, real_lng] = realCoordinates.split(',');
            
                marker([parseFloat(plan_lat), parseFloat(plan_lng)], {
                    icon: icon({
                        iconSize: [40, 40],
                        iconAnchor: [20, 40],
                        iconUrl: '/assets/img/marker-icon-green.png',
                        shadowUrl: '/assets/img/marker-shadow.png',
                    })
                }).addTo(this.map!).bindPopup(planPIN);
    
                marker([parseFloat(real_lat), parseFloat(real_lng)], {
                    icon: icon({
                        iconSize: [40, 40],
                        iconAnchor: [20, 40],
                        iconUrl: '/assets/img/marker-icon-orange.png',
                        shadowUrl: '/assets/img/marker-shadow.png',
                    })
                }).addTo(this.map!).bindPopup(realPIN);  
    
                // Calculate distance in meters
                this.distance = Math.round(new L.LatLng(parseFloat(plan_lat), parseFloat(plan_lng)).distanceTo(new L.LatLng(parseFloat(real_lat), parseFloat(real_lng)))); 
              }

        // An array of all points for centering the map by coordinates

              var latlngs: [number, number][] = [];
              this.map.eachLayer(layer => {
                  if (layer instanceof L.Marker) {
                      latlngs.push([layer.getLatLng().lat, layer.getLatLng().lng]);
                  }
              });
              if (latlngs.length > 0) {
                this.map.fitBounds(latlngs);
            } 
        };

        
    }

    backToRL(routeId:number){
        this.service.updateRL(routeId).pipe(takeUntil(this.$destroy)).subscribe({
            complete: () => {
                this.router.navigate(['routelist/create'])
            }
        });
    }

    getUsersCourier(): void{
        this.rlBuilderService.getUsers().pipe(takeUntil(this.$destroy)).subscribe(
            {
                next: value => this.users =  value,
                complete: () => {
                    this.couriers = this.users.filter(item => item.professionId === 7 );
                }
            }
        )
    }

    getEditPurposes(): void{
        this.service.getEditPurposes().pipe(takeUntil(this.$destroy)).subscribe(
            {
                next: value => this.editPurposes =  value.data
            }
        );
    }

    deleteRoute(id: number): void{
        this.service.deleteRoute(id)
            .pipe(takeUntil(this.$destroy))
            .subscribe({
                complete: () => {
                    this.close();
                }
            });
    }

    showPathModal(content: any , id: number): void{
        const routeList = this.service.AllOrders.filter(order => order.route_list_id === id);
        this.modalService.open(content, {size: 'xl'});
        this.initializeMap();
        this.addMarkersShowPath(routeList[0]);
    }


    addMarkersShowPath(routeList): void {
        this.map.eachLayer(layer => {
            if (layer instanceof L.Marker || layer instanceof L.Polyline) {
                this.map.removeLayer(layer);
            }
        });

        if (routeList?.coordinates) {
            routeList?.coordinates.forEach(step => {
                const lat = parseFloat(step.latitude);
                const lng = parseFloat(step.longitude);
                marker([lat, lng]
                    , {
                        icon: icon({
                            iconSize: [ 40, 40 ],
                            iconAnchor: [ 20, 40 ],
                            iconUrl: '/assets/img/pin-purple.png',
                            // shadowUrl: '/assets/img/marker-shadow.png',
                        }, )
                    }
                ).addTo(this.map!).bindPopup(this.datePipe.transform(step.created_at, 'HH:mm:ss'));
            });
            routeList?.orders.forEach(step => {
                const lat = parseFloat(step.plan_location.split(',')[0]);
                const lng = parseFloat(step.plan_location.split(',')[1]);
                marker([lat, lng]
                    , {
                        icon: icon({
                            iconSize: [ 40, 40 ],
                            iconAnchor: [ 20, 40 ],
                            iconUrl: '/assets/img/marker-icon-orange.png',
                            shadowUrl: '/assets/img/marker-shadow.png',
                        }, )
                    }
                ).addTo(this.map!).bindPopup(step.address);
            });
            const latLngs = routeList?.coordinates.map(step => latLng(step.latitude, step.longitude));
            if (latLngs.length > 0){
                const route = polyline(latLngs, { color: 'red' }).addTo(this.map!);
                this.map!.fitBounds(route.getBounds());
            }



        }
    }

    postDelayComment(orderId: number, comment: string): void{
        this.service.postDelayComment(orderId, comment)
            .pipe(takeUntil(this.$destroy))
            .subscribe({
                complete: () => {
                    this.Alltoasts.showSuccess();
                }
            });
    }


    clear(obj: any): void {
        obj.delay_comment = '';
        this.service.deleteDelayComment(obj.order_id)
            .pipe(takeUntil(this.$destroy))
            .subscribe({
                complete: () => {
                    this.Alltoasts.showSuccess();
                }
            });
    }

}

